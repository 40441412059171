<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex flex-shrink-0">
    <!--begin::Invite user-->
    <!-- <div class="d-flex ms-3">
      <button v-if="!user.gc_token" @click="loadAuthScreen" class="
          btn btn-flex
          flex-center
          btn-bg-white btn-text-gray-500 btn-active-color-primary
          w-md-auto
          h-40px
          px-3 px-md-6
          text-primary"
          tooltip="Global Control"
        >
        <span class="d-none d-md-inline">Global Control</span>
      </button>
      <button v-else class="btn btn-flex flex-center btn-bg-white text-primary btn-text-gray-500 btn-active-color-primary w-md-auto h-40px px-3 ps-md-6 pe-md-4" tooltip="Global Control">
         <div class="d-flex flex-column text-right pr-3">
                <a class="text-dark-75 font-weight-bold font-size-sm" href="https://app.globalcontrol.io/" target="_blank">Go To Global Control</a>
                <span id="global-email" class="font-weight-bolder text-muted fs-9 d-block">{{user.gc_email}}</span>
            </div>
            <i @click="logoutGcAccount()" class="bi bi-box-arrow-right fs-1 ms-3 d-block"></i>
      </button>
    </div> -->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script>
import { defineComponent } from "vue";
import  {mapActions,mapGetters} from "vuex"
export default defineComponent({
  name: "topbar",
  components: {},
  computed:{
    ...mapGetters({
      user:"Auth/user"
    })
  },
  methods:{
    ...mapActions({
      logoutGc:"Auth/logoutGc",
      updateGcToken:"Auth/updateGcToken",
      connectGc:'Auth/connectGc',
    }),
    loadAuthScreen(){
      window.GcApi.onAddAccount=(accessToken)=>{
        var payload = {
          user_id : this.user.id,
          gc_token : accessToken
        }
        console.log("accessToken",accessToken);
        // this.updateGcToken(payload)
        this.connectGc(payload)
        window.GcApi.init({accessToken:accessToken});
        window.GcApi.hideAuthScreen()
      }
      if(window.GcApi) window.GcApi.showAuthScreen("gc_auth");
      console.log("LOAD_AUTH",window.GcApi);
    },
    logoutGcAccount(){
      this.$swal.fire({
        title: "Are you sure want to logout?",
        // text: "You can not revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Logout",
      })
          .then((result) => {
            if (result.isConfirmed) {
              // window.GcApi.logOut();
              this.logoutGc()
            }
          });
    }
  }
});
</script>
<style>
.bg-gray-300{
  background: #E4E6EF !important;
}
</style>
