
import { defineComponent, computed } from "vue"
import KTTopbar from "@/layout/header/Topbar.vue"
import { headerWidthFluid } from "@/core/helpers/config"
import { headerFixed, headerFixedOnMobile } from "@/core/helpers/config"
import { RouterLink } from "vue-router"
export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
    breadcrumbs: Array,
  },
  components: {
    KTTopbar,
  },
  setup() {
    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value
      } else {
        return headerFixedOnMobile.value
      }
    })

    return {
      headerWidthFluid,
      isHeaderSticky,
    }
  },
})
